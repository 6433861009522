<template>
  <li>
    <b-button
      v-if="$hasPrivileges(
          $privileges('CAN_PUBLISH_ITEMS'),
          isSysAdmin,
          privilegesForItem,
        ) && showPublishButton"
      @click="publishProject"
      class="mr-2 green-button-link"
    >
      <b-icon icon="cloud-upload-fill" aria-hidden="true" class="top--1 mr-1"></b-icon>
      {{ $t('buttons.publishProject') }}
    </b-button>
    <b-button
      v-else-if="$hasPrivileges(
          $privileges('CAN_PUBLISH_ITEMS'),
          isSysAdmin,
          privilegesForItem,
        ) && showPublishChangesButton"
      @click="publishChangesOfProject"
      class="mr-2 green-button-link"
    >
      <b-icon icon="cloud-upload-fill" aria-hidden="true" class="top--1 mr-1"></b-icon>
      {{ $t('buttons.publishChanges') }}
    </b-button>
    <b-button
      @click="previewProject"
      class="mr-4 transparent-white-button"
    >
      <b-icon icon="eye" aria-hidden="true" class="top--1 mr-1"></b-icon>
      {{ $t('buttons.preview') }}
    </b-button>
    <!-- <b-button
      v-if="!isProjectPublished"
      class="dark-grey-buttons mr-2"
    >
      <b-icon
        id="header-tooltip-eye-changed"
        icon="link"
        aria-hidden="true"
        class="top--1 mr-1"
      ></b-icon>
    </b-button> -->
    <b-button
      v-if="isProjectPublished"
      class="dark-grey-buttons mr-2"
      @click="showUrlModal"
    >
      <b-icon
        id="header-tooltip-eye-changed"
        icon="link"
        aria-hidden="true"
        class="top--2"
      ></b-icon>
    </b-button>
    <b-tooltip
      v-if="!isProjectPublished"
      target="header-tooltip-eye-changed"
    >
      <small>{{ $t('webmag.publishProjectChangesFirst') }}</small>
    </b-tooltip>
    <b-tooltip
      v-if="isProjectPublished"
      target="header-tooltip-eye-changed"
    >
      <small>{{ $t('webmag.showItemUrl') }}</small>
    </b-tooltip>
  </li>
</template>

<script>
import SetPreviewWindowInCache from '@/graphQlQueries/mutations/setPreviewWindowInCache';
import SetProjectHasToBePublishedInCache from '@/graphQlQueries/mutations/setProjectHasToBePublishedInCache';
import SetItemUrlWindowFromCache from '@/graphQlQueries/mutations/setItemUrlWindowFromCache';
import GetProjectHasToBePublishedFromCache from '@/graphQlQueries/queries/getProjectHasToBePublishedFromCache';

export default {
  name: 'HeaderActionProjectButtons',
  data: () => ({
    isProjectPublished: true,
    hasProjectChanged: false,
  }),
  props: {
    isSysAdmin: {
      type: Boolean,
      required: true,
    },
    privilegesForItem: {
      type: Array,
    },
  },
  apollo: {
    isPublishedStatusQuery: {
      query: GetProjectHasToBePublishedFromCache,
      update(data) {
        if (!data.projectHasToBePublished.publishButtonIsPressed) {
          this.isProjectPublished = data.projectHasToBePublished.isPublished;
          this.hasProjectChanged = data.projectHasToBePublished.hasChanged;
        }
      },
    },
  },
  computed: {
    showPublishButton() {
      return !this.$apollo.loading && !this.isProjectPublished;
    },
    showPublishChangesButton() {
      return (!this.$apollo.loading && this.isProjectPublished && this.hasProjectChanged);
    },
  },
  methods: {
    showUrlModal() {
      this.$apollo.mutate({
        mutation: SetItemUrlWindowFromCache,
        variables: {
          openItemUrlWindow: true,
          itemPath: null,
        },
      });
    },
    publishProject() {
      this.$apollo.mutate({
        mutation: SetProjectHasToBePublishedInCache,
        variables: {
          isPublished: true,
          hasChanged: false,
          publishButtonIsPressed: true,
        },
      });
    },
    publishChangesOfProject() {
      this.$apollo.mutate({
        mutation: SetProjectHasToBePublishedInCache,
        variables: {
          isPublished: true,
          hasChanged: true,
          publishButtonIsPressed: true,
        },
      });
    },
    previewProject() {
      document.body.className = `${document.body.className} preview-modal-open`;
      this.$apollo.mutate({
        mutation: SetPreviewWindowInCache,
        variables: {
          isPreviewWindowOpen: true,
        },
      });
    },
  },
};
</script>
