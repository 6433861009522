import gql from 'graphql-tag';

export default gql`
mutation($openItemUrlWindow: Boolean!, $itemPath: String) {
  setItemUrlWindow(
    openItemUrlWindow: $openItemUrlWindow
    itemPath: $itemPath
  ) @client
}
`;
