var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    [
      _vm.$hasPrivileges(
        _vm.$privileges("CAN_PUBLISH_ITEMS"),
        _vm.isSysAdmin,
        _vm.privilegesForItem
      ) && _vm.showPublishButton
        ? _c(
            "b-button",
            {
              staticClass: "mr-2 green-button-link",
              on: { click: _vm.publishProject }
            },
            [
              _c("b-icon", {
                staticClass: "top--1 mr-1",
                attrs: { icon: "cloud-upload-fill", "aria-hidden": "true" }
              }),
              _vm._v(" " + _vm._s(_vm.$t("buttons.publishProject")) + " ")
            ],
            1
          )
        : _vm.$hasPrivileges(
            _vm.$privileges("CAN_PUBLISH_ITEMS"),
            _vm.isSysAdmin,
            _vm.privilegesForItem
          ) && _vm.showPublishChangesButton
        ? _c(
            "b-button",
            {
              staticClass: "mr-2 green-button-link",
              on: { click: _vm.publishChangesOfProject }
            },
            [
              _c("b-icon", {
                staticClass: "top--1 mr-1",
                attrs: { icon: "cloud-upload-fill", "aria-hidden": "true" }
              }),
              _vm._v(" " + _vm._s(_vm.$t("buttons.publishChanges")) + " ")
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-button",
        {
          staticClass: "mr-4 transparent-white-button",
          on: { click: _vm.previewProject }
        },
        [
          _c("b-icon", {
            staticClass: "top--1 mr-1",
            attrs: { icon: "eye", "aria-hidden": "true" }
          }),
          _vm._v(" " + _vm._s(_vm.$t("buttons.preview")) + " ")
        ],
        1
      ),
      _vm.isProjectPublished
        ? _c(
            "b-button",
            {
              staticClass: "dark-grey-buttons mr-2",
              on: { click: _vm.showUrlModal }
            },
            [
              _c("b-icon", {
                staticClass: "top--2",
                attrs: {
                  id: "header-tooltip-eye-changed",
                  icon: "link",
                  "aria-hidden": "true"
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.isProjectPublished
        ? _c("b-tooltip", { attrs: { target: "header-tooltip-eye-changed" } }, [
            _c("small", [
              _vm._v(_vm._s(_vm.$t("webmag.publishProjectChangesFirst")))
            ])
          ])
        : _vm._e(),
      _vm.isProjectPublished
        ? _c("b-tooltip", { attrs: { target: "header-tooltip-eye-changed" } }, [
            _c("small", [_vm._v(_vm._s(_vm.$t("webmag.showItemUrl")))])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }